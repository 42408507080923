import Typography from "@mui/material/Typography";
import {
  Button,
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiBuildings, BiTrash, BiEdit } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { contact } from "../../../../app/slices/contactSlice";
import { useDispatch, useSelector } from "react-redux";

const ContactTabel = () => {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const contacts  = useSelector((state) => state.contact.contacts);

  const columns = [
    {
      field: "name",
      headerName: "الاسم",
      flex: 1,
    },
    {
      field: "phone_number",
      headerName: " رقم الهاتف",
      flex: 1,
    },
    {
      field: "province",
      headerName: "المحافظة",
      flex: 1,
    },
    {
      field: "city",
      headerName: "المدينة",
      flex: 1,
    },
    {
      field: "status",
      headerName: "الحالة",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              variant="contained"
              color="warning"
              size="small"
              style={{ marginLeft: 10 }}
              onClick={() => dispatch(contact.getById(params.row.id))}
            >
              <BiEdit />
            </IconButton>
            <IconButton
              variant="contained"
              color="error"
              size="small"
              style={{ marginLeft: 10 }}
              onClick={() => dispatch(contact.deleteById(params.row.id))}
            >
              <BiTrash />
            </IconButton>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    // if (!search) {
    //   dispatch(contact.getAll({skip: 0,take: 15,}));
    // }
    const timeout = setTimeout(() => {
      // if (search) {
        dispatch(contact.getAll({ search: search, skip: 0, take: 15 }));
      // }
    }, 500);
    return () => clearTimeout(timeout);
  }, [search]); //search

  const navigate = useNavigate();

  const goTo = (path) => {
    // navigate(path);
  };

  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={{}}>
          <Grid item xs={2}>
            <TextField
              size="small"
              onChange={(e) => {
                  setSearch(e.target.value);
              }}
              label="بحث عن جهة اتصال "
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={contacts.data}
                columns={columns}
                pageSize={15}
                rowCount={contacts.total}
                loading={contacts.loading}
                rowHeight={65}
                pagination
                paginationMode="server"
                selectionModel={[]}
                onPageChange={(newPage) =>
                  dispatch(
                    contact.getAll({
                      search: search,
                      skip: newPage * 15,
                      take: 15,
                    })
                  )
                }
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ContactTabel;
