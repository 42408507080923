import dayjs from "dayjs";
import {
    createSlice
} from "@reduxjs/toolkit";
import {
    showNotification
} from "./notificationSlice";
import {
    factory
} from "../../api/apiFactory";
const holidaysApi = factory.get("holidays");
const initialState = {
    holiday: {
        loading: false,
        dialog: false,
        form: {
            id: "",
            name: "",
            note: "",
            date: dayjs().format("YYYY-MM-DD"),
        },
    },
    holidays: {
        loading: false,
        data: [],
        total: 0,
    },
}

export const holidaysSlice = createSlice({
    name: "holidays",
    initialState,
    reducers: {
        setDialog: (state, action) => {
            state.holiday.dialog = !state.holiday.dialog
        },
        setDataTable: (state, {
            payload
        }) => {
            state.holidays.data = payload.data.data
            state.holidays.total = payload.data.total
        },
        setById: (state, {
            payload
        }) => {
            state.holiday.form.id = payload.id
            state.holiday.form.name = payload.name
            state.holiday.form.note = payload.note
            state.holiday.form.date = payload.date
        },
        setLoading: (state, action) => {
            state[action.payload].loading = !state[action.payload].loading;
        },
        resetForm: (state, action) => {
            state.holiday.form = initialState.holiday.form
        }
    }
})

export const {
    setLoading,
    setDialog,
    setById,
    setDataTable,
    resetForm
} = holidaysSlice.actions

export default holidaysSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
    try {
        dispatch(setLoading('holidays'));
        const res = await holidaysApi.getAll(params)
        dispatch(setDataTable(res));
        dispatch(setLoading('holidays'));

    } catch (err) {
        dispatch(setLoading('holidays'));
        throw new Error(err);
    }
};
const deleteById = (id) => async (dispatch) => {
    try {
        await holidaysApi.delete(id)
        dispatch(showNotification({
            message: "تم حذف العطلة بنجاح",
            type: "success"
        }));
        dispatch(getAll())
    } catch (err) {
        throw new Error(err);
    }
};
const getById = (id) => async (dispatch) => {
    try {
        dispatch(setLoading('holidays'));
        const res = await holidaysApi.getById(id)
        dispatch(setById(res.data.data[0]));

        dispatch(setDialog())
        dispatch(setLoading('holidays'));
    } catch (err) {
        dispatch(setLoading('holidays'));
        throw new Error(err);
    }
};
const create = (data) => async (dispatch) => {
    try {
        await holidaysApi.create(data)
        dispatch(showNotification({
            message: "تم اضافة العطلة بنجاح",
            type: "success"
        }));
        dispatch(setDialog())
        dispatch(getAll())
    } catch (err) {
        dispatch(setLoading('holiday'));
        throw new Error(err);
    }
};
const update = (data) => async (dispatch) => {
    try {
        await holidaysApi.update(data.id, data)
        dispatch(showNotification({
            message: "تم تعديل العطلة بنجاح",
            type: "success"
        }));
        dispatch(setDialog())
        dispatch(getAll())
    } catch (err) {
        dispatch(setLoading('holiday'));
        throw new Error(err);
    }
};

export const Holidays = {
    getAll,
    deleteById,
    getById,
    create,
    update
}