import dayjs from "dayjs";
import {
    createSlice
} from "@reduxjs/toolkit";
import {
    showNotification
} from "./notificationSlice";
import {
    factory
} from "../../api/apiFactory";
const contactsApi = factory.get("contact");
const initialState = {
    contact: {
        loading: false,
        dialog: false,
        form: {
            id: "",
            name: "",
            province: "",
            city: "",
            age: 0,
            address: "",
            is_male: 1,
            email: "",
            blood_type: "",
            how_know_us: ""
        },
    },
    contacts: {
        loading: false,
        data: [],
        total: 0,
    },
}

export const contactsSlice = createSlice({
    name: "contacts",
    initialState,
    reducers: {
        setDialog: (state, action) => {
            state.contact.dialog = !state.contact.dialog
        },
        setDataTable: (state, {
            payload
        }) => {
            state.contacts.data = payload.data.result
            state.contacts.total = payload.data.count
        },
        setById: (state, {
            payload
        }) => {
            state.contact.form = payload
        },
        setLoading: (state, action) => {
            state[action.payload].loading = !state[action.payload].loading;
        },
        resetForm: (state, action) => {
            state.contact.form = initialState.contact.form
        }
    }
})

export const {
    setLoading,
    setDialog,
    setById,
    setDataTable,
    resetForm
} = contactsSlice.actions

export default contactsSlice.reducer;

//axios
const getAll = (data) => async (dispatch) => {
    try {
        dispatch(setLoading('contacts'));
        const res = await contactsApi.getAll(data)
        dispatch(setDataTable(res));
        dispatch(setLoading('contacts'));

    } catch (err) {
        dispatch(setLoading('contacts'));
        throw new Error(err);
    }
};
const deleteById = (id) => async (dispatch) => {
    try {
        await contactsApi.delete(id)
        dispatch(showNotification({
            message: "تم حذف بنجاح",
            type: "success"
        }));
        dispatch(getAll())
    } catch (err) {
        throw new Error(err);
    }
};

const getById = (id) => async (dispatch) => {
    try {
        dispatch(setLoading('contacts'));
        const res = await contactsApi.getById(id)
        dispatch(setById(res.data.result[0]));

        dispatch(setDialog())
        dispatch(setLoading('contacts'));
    } catch (err) {
        dispatch(setLoading('contacts'));
        throw new Error(err);
    }
};
const create = (data) => async (dispatch) => {
    try {
        await contactsApi.create(data)
        dispatch(showNotification({
            message: "تم الاضافة بنجاح",
            type: "success"
        }));
        dispatch(setDialog())
        dispatch(getAll())
    } catch (err) {
        dispatch(setLoading('contact'));
        throw new Error(err);
    }
};
const update = (data) => async (dispatch) => {
    try {
        await contactsApi.update(data.id, data)
        dispatch(showNotification({
            message: "تم التعديل بنجاح",
            type: "success"
        }));
        dispatch(setDialog())
        dispatch(getAll())
    } catch (err) {
        dispatch(setLoading('contact'));
        throw new Error(err);
    }
};

export const contact = {
    getAll,
    deleteById,
    getById,
    create,
    update
}