import dayjs from "dayjs";
import {
    createSlice
} from "@reduxjs/toolkit";
import {
    showNotification
} from "./notificationSlice";
import {
    factory
} from "../../api/apiFactory";
const userApi = factory.get("user");

const initialState = {
    user: {
        loading: false,
        data: [
            {
                name: "",
                active_plan:{}
            }
        ],
        total: 0,
    }
}

export const usersSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state[action.payload].loading = !state[action.payload].loading;
        },
        setDataTable: (state, {
            payload
        }) => {
            state.user.data = payload.data.result
        },
    },
})

export const {
    setLoading,
    setDataTable,
    setErrors,
    setUser,
    resetForm
} = usersSlice.actions

export default usersSlice.reducer;


//axios 
const getAll = () => async (dispatch) => {
    try {
        dispatch(setLoading('user'));
        const res = await userApi.getAll()
        dispatch(setDataTable(res));
        dispatch(setLoading('user'));

    } catch (err) {
        dispatch(setLoading('user'));
        throw new Error(err);
    }
};

const login = (data) => async (dispatch) => {
    try {
        // dispatch(setLoading('users'));
        const res = await userApi.login(data)
        return Promise.resolve(res)
        // console.log(res.data.token)
        // dispatch(setDataTable(res));
        // dispatch(setLoading('users'));

    } catch (err) {
        // dispatch(setLoading('users'));
        dispatch(showNotification({
            message: err.response.data.message,
            type: "error"
        }));
        // console.log(err.response.data.message)
        throw new Error(err);
    }
};

const logout = () => async (dispatch) => {
    // try {
    //     const res = await userApi.logout()
    //     localStorage.removeItem('token')
    //     return Promise.resolve(res)

    // } catch (err) {
    //     dispatch(showNotification({
    //         message: err.response.data.message,
    //         type: "error"
    //     }));
    //     throw new Error(err);
    // }
};


export const User = {
    getAll,
    login,
    logout
}