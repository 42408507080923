import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, Checkbox } from "@mui/material";
import { useEffect, forwardRef, useState, Fragment } from "react";

// import { getAllReferees, clearReferee } from "../../app/modules/refereesSlice";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { factory } from "../../api/apiFactory";
import CircularProgress from "@mui/material/CircularProgress";

const othersApi = factory.get("others");

export const SelectProvince = forwardRef((props, ref) => {
  const [options, setOptions] = useState([
    "بغداد","النجف","كربلاء","ديالى","اربيل","البصرة","الانبار","كركوك","سليمانية","الموصل","دهوك","بابل","ميسان","واسط","المثنى","القادسية","ذي قار","صلاح الدين"
  ]);
  const [loading, setLoading] = useState(false);
  // const [selectedVal, setSelectedVal] = useState("");

  // const { referees } = useSelector((state) => state.referee);
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   setLoading(true);
  //   othersApi
  //     .province()
  //     .then((res) => {
  //       setLoading(false);
  //       setOptions(res.data.data);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //     });
  //   //   dispatch(getAllReferees());
  //   //   return () => {
  //   //     dispatch(clearReferee());
  //   //   };
  // }, []);

  // useEffect(() => {
  //   if (referees.data.length > 0) {
  //     const options = referees.data.map((option) => ({
  //       id: option.id,
  //       label: option.name,
  //     }));
  //     setOptions(options);
  //   }
  // }, [referees.data]);

  // function handleSelectedChange() {
  //   const selected = options.filter((option) => option.id == value);
  //   setSelectedVal(selected[0]);
  // }

  // useEffect(() => {
  //   if (options.length > 0) {
  //     handleSelectedChange();
  //   }
  // }, [options]);

  // useEffect(() => {
  //   handleSelectedChange();
  // }, [value]);

  return (
    <Autocomplete
      ref={ref}
      options={options}
      {...props}
      size="small"
      getOptionLabel={(option) => option}
      isOptionEqualToValue={(option, value) => option === value}
      renderInput={(params) => (
        <TextField
          {...params}
          label="المحافظة"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
});
