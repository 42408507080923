import axios from "axios";
const authInterceptor = (config) => {
    const token = localStorage.getItem("token");
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["Content-Type"] = "application/json";
    return config;
};

const api = axios.create({
    baseURL: process.env.REACT_APP_API,
    // baseURL: 'http://192.168.0.82:8000',
});

api.interceptors.request.use(authInterceptor);

api.interceptors.response.use((response) => {
    return response
},
    (error) => {
        // eslint-disable-next-line default-case
        console.log(error)
        switch (error.response.status) {
            case 401:
                localStorage.removeItem("token")
                window.location.href = "/login";
                break;
                case 0:
                localStorage.removeItem("token")
                window.location.href = "/login";
                break;
        }

        return Promise.reject(error);
    }
)
export default api;