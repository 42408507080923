import { useState, forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  Switch,
  FormControlLabel,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  Appointment,
} from "../../../app/slices/appointmentSlice";
import { Controller, useForm } from "react-hook-form";
import { IoAddCircleOutline } from "react-icons/io5";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SelectContact } from "../../../components/Selectors/SelectContact";
import { SelectSection } from "../../../components/Selectors/SelectSection";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppointmentForm = () => {
  const _appointment= useSelector((state) => state.appointment.appointment);

  const isAdd = _appointment.form.id ? false : true;
  const { control, reset, watch, register, handleSubmit, setValue } = useForm({
    defaultValues: _appointment.form,
  });

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(Appointment.create(data));
    } else {
      dispatch(Appointment.update(data));
    }
  };
  useEffect(() => {
    reset({ ..._appointment.form });
  }, [_appointment.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, []);

  const dispatch = useDispatch();

  const handlDialog = () => {
    dispatch(setDialog());
  };

  return (
    <Dialog
      open={_appointment.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handlDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="contact"
                control={control}
                render={({ field }) => (
                  <SelectContact 
                  value={field.value}
                  onChange={(e, newValue) => {
                    setValue("contact_id", newValue.id);
                  }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="section"
                control={control}
                render={({ field }) => (
                  <SelectSection 
                  value={field.value}
                  onChange={(e, newValue) => {
                    setValue("section_id", newValue.id);
                  }}
                  />
                )}
              />
            </Grid>
     <Grid item xs={12} md={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="appointment_date"
                  control={control}
                  render={({ field }) => (
                    <DateTimePicker
                      label="الموعد"
                      inputFormat="YYYY-MM-DD hh:mm"
                      value={field.value}
                      onChange={(newValue) => {
                        setValue(
                          "appointment_date",
                          newValue ? newValue.format("YYYY-MM-DD hh:mm") : null
                        );
                      }}
                      renderInput={(params) => (
                        <TextField size="small" fullWidth {...params} />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label=" ملاحظات"
                    multiline
                    rows={4}
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="send_message"
                control={control}
                render={({ field }) => (
                  <FormControlLabel control={ 
                    <Switch
                      {...field}
                      checked={field.value}
                    />
                    } label="تذكير؟" />
                )}
              />
            </Grid>
       

            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AppointmentForm;
