import dayjs from "dayjs";
import {
    createSlice
} from "@reduxjs/toolkit";
import {
    showNotification
} from "./notificationSlice";
import {
    factory
} from "../../api/apiFactory";
const messageApi = factory.get("message");
const initialState = {
    message: {
        loading: false,
        dialog: false,
        form: {
            all:true,
            message:""
        },
    },
    messages: {
        loading: false,
        data: [],
        total: 0,
    },
}

export const messageSlice = createSlice({
    name: "message",
    initialState,
    reducers: {
        setDialog: (state, action) => {
            state.message.dialog = !state.message.dialog
        },
        setDataTable: (state, {
            payload
        }) => {
            state.messages.data = payload.data.result
            state.messages.total = payload.data.count
        },
        setById: (state, {
            payload
        }) => {
            state.message.form.id = payload.id
            state.message.form.name = payload.name
            state.message.form.note = payload.note
            state.message.form.date = payload.date
        },
        setLoading: (state, action) => {
            state[action.payload].loading = !state[action.payload].loading;
        },
        resetForm: (state, action) => {
            state.message.form = initialState.message.form
        }
    }
})

export const {
    setLoading,
    setDialog,
    setById,
    setDataTable,
    resetForm
} = messageSlice.actions

export default messageSlice.reducer;

//axios
const getAll = (data) => async (dispatch) => {
    try {
        dispatch(setLoading('messages'));
        const res = await messageApi.getAll(data)
        dispatch(setDataTable(res));
        dispatch(setLoading('messages'));

    } catch (err) {
        dispatch(setLoading('messages'));
        throw new Error(err);
    }
};
const deleteById = (id) => async (dispatch) => {
    try {
        await messageApi.delete(id)
        dispatch(showNotification({
            message: "تم الحذف بنجاح",
            type: "success"
        }));
        dispatch(getAll())
    } catch (err) {
        throw new Error(err);
    }
};
// const getById = (id) => async (dispatch) => {
//     try {
//         dispatch(setLoading('messages'));
//         const res = await messageApi.getById(id)
//         dispatch(setById(res.data.data[0]));

//         dispatch(setDialog())
//         dispatch(setLoading('messages'));
//     } catch (err) {
//         dispatch(setLoading('messages'));
//         throw new Error(err);
//     }
// };
const SendMessageAll = (data) => async (dispatch) => {
    try {
        await messageApi.create(data)
        dispatch(showNotification({
            message: "تم الارسال يرجى الانتظار لحين اكمال عملية الارسال",
            type: "success"
        }));
        dispatch(setDialog())
        dispatch(getAll())
    } catch (err) {
        dispatch(setLoading('message'));
        throw new Error(err);
    }
};

const ResendMessage = (data) => async (dispatch) => {
    try {
        await messageApi.create(data)
        dispatch(showNotification({
            message: "تم الارسال",
            type: "success"
        }));
        dispatch(getAll())
    } catch (err) {
        dispatch(setLoading('message'));
        throw new Error(err);
    }
};
// const update = (data) => async (dispatch) => {
//     try {
//         await messageApi.update(data.id, data)
//         dispatch(showNotification({
//             message: "تم تعديل العطلة بنجاح",
//             type: "success"
//         }));
//         dispatch(setDialog())
//         dispatch(getAll())
//     } catch (err) {
//         dispatch(setLoading('message'));
//         throw new Error(err);
//     }
// };

export const Message = {
    getAll,
    deleteById,
    SendMessageAll,
    ResendMessage
    // create,
    // update
}