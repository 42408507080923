import {
    createSlice
} from "@reduxjs/toolkit";

const initialState = {
    data: {
        show: false,
        message: "test",
        type: "success"
    },

}

export const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        setNotification: (state, {
            payload
        }) => {
            state.data.show = payload.show;
            state.data.message = payload.message;
            state.data.type = payload.type;
        },

    }
})

export const {
    setNotification,
} = notificationSlice.actions

export default notificationSlice.reducer;

//axios 
export const showNotification = (data) => async (dispatch) => {
    try {
        data.show = true
        dispatch(setNotification(data));
    } catch (err) {
        throw new Error(err);
    }
};

export const hideNotification = (data) => async (dispatch) => {
    try {
        dispatch(setNotification({
            message: "",
            type: data.type,
            show: false
        }));
    } catch (err) {
        throw new Error(err);
    }
};