import {
    lazy
} from 'react';

// project imports
import MainLayout from '../layout/MainLayout';
import Contact from '../views/contact';
import Message from '../views/message';
import Appointment from '../views/appointment';
import Loadable from './../components/Loadable';

// dashboard routing
const Home = Loadable(lazy(() => import('../views/home')));
const Holiday = Loadable(lazy(() => import('../views/holiday')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: < MainLayout />,
    children: [
        {
            path: '/',
            element: < Home />
        },
        {
            path: '/contact',
            element: < Contact />
        },
        {
            path: '/message',
            element: < Message />
        },
        {
            path: '/appointment',
            element: < Appointment />
        },
        {
            path: '/holidays',
            children: [{
                path: '/holidays',
                element: < Holiday />
            },
            {
                path: '/holidays/add',
                element: < Holiday />
            },
            {
                path: '/holidays/edit/:id',
                element: < Holiday />
            }
            ]
        },

    ]
};

export default MainRoutes;