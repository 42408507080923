import { useState, forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  contact,
} from "../../../app/slices/contactSlice";
import { Controller, useForm } from "react-hook-form";
import { IoAddCircleOutline } from "react-icons/io5";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Stack } from "@mui/system";
import { SelectProvince } from "../../../components/Selectors/SelectProvince";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ContactForm = () => {
  const _contact = useSelector((state) => state.contact.contact);

  const isAdd = _contact.form.id ? false : true;
  const { control, reset, watch, register, handleSubmit, setValue } = useForm({
    defaultValues: _contact.form,
  });

  const onSubmit = (data) => {
    if (data.phone_number.substr(0, 1) == '0')
      data.phone_number = data.phone_number.slice(1)
    if (isAdd) {
      dispatch(contact.create(data));
    } else {
      dispatch(contact.update(data));
    }
  };
  useEffect(() => {
    reset({ ..._contact.form });
  }, [_contact.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, []);

  const dispatch = useDispatch();

  const handlDialog = () => {
    dispatch(setDialog());
  };

  return (
    <Dialog
      open={_contact.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handlDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label=" الاسم"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="phone_number"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label=" رقم الهاتف"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="age"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="العمر"
                    fullWidth
                    type={"number"}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="province"
                control={control}
                render={({ field }) => (
                  // <TextField
                  //   size="small"
                  //   label="المحافظة"
                  //   fullWidth
                  //   {...field}
                  // />
                  <SelectProvince
                    value={field.value}
                    onChange={(e, newValue) => {
                      setValue("province", newValue);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label=" المدينة"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label=" البريد الالكتروني"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="blood_type"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="فصيلة الدم"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="how_know_us"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label=" كيف سمعت عنا؟"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="is_male"
                control={control}
                render={({ field }) => (
                  <Stack direction={"row"} spacing={1}>
                    <Typography>Female</Typography>
                    <Switch checked={field.value}  {...field} />
                    <Typography>Male</Typography>

                  </Stack>
                )}
              />
            </Grid>

            {/* <Grid item xs={12} md={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <DesktopDatePicker
                      label="تاريخ العطلة"
                      inputFormat="YYYY-MM-DD"
                      value={field.value}
                      onChange={(newValue) => {
                        setValue(
                          "date",
                          newValue ? newValue.format("YYYY-MM-DD") : null
                        );
                      }}
                      renderInput={(params) => (
                        <TextField size="small" fullWidth {...params} />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid> */}

            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ContactForm;
