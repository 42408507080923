import React from "react";
import {
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
  useMediaQuery,
  Divider,
  Drawer,
  Typography,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import LogoutIcon from '@mui/icons-material/Logout';

import { styled, useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { setDrawer } from "../../../app/slices/appSlice";
import { User } from "../../../app/slices/userSlice";
import { useNavigate } from "react-router";

const Appbar = () => {
  const navigate = useNavigate();

  const drawerWidth = 210;
  const theme = useTheme();
  
  const leftDrawerOpened = useSelector((state) => state.app.is_drawer_opened);
  const dispatch = useDispatch();

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    }),
  }));

  return (
    <Box>
      <AppBar
        position="fixed"
        elevation={1}
        open={leftDrawerOpened}
        sx={{
          bgcolor: theme.palette.primary.main,
          minHeight: "65px",
          height: "25px",
        }}
      >
        <Toolbar >
          <IconButton
            onClick={() => dispatch(setDrawer())}
            color="inherit"
            aria-label="open drawer"
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h2" component="div" sx={{ flexGrow: 1 }}>
            خدمة الرسائــــل
          </Typography>
          {/* <ProfileMenu /> */}

          <IconButton
            onClick={() => {
              localStorage.removeItem('token')
              navigate('/login')
            }}
            color="inherit"
            aria-label="Logout"
            sx={{ mr: 2 }}
          >
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Appbar;
