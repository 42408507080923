import {
    configureStore
} from '@reduxjs/toolkit'
import {
    setupListeners
} from '@reduxjs/toolkit/query';

import appSlice from './slices/appSlice';
import contactSlice from './slices/contactSlice';
import holidaysSlice from './slices/holidaysSlice';
import notificationSlice from './slices/notificationSlice';
import userSlice from './slices/userSlice';
import messageSlice from './slices/messageSlice';
import appointmentSlice from './slices/appointmentSlice';


export const store = configureStore({
    reducer: {
        app: appSlice,
        holidays: holidaysSlice,
        notification: notificationSlice,
        contact: contactSlice,
        message: messageSlice,
        appointment: appointmentSlice,
        user: userSlice
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

setupListeners(store.dispatch);