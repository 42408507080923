import { Card, CardHeader, CardContent, Typography, Grid } from "@mui/material";
import ContactTable from "./home/table";
import ContactHeader from "./home/header";
import React from "react";

const Contact = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ContactHeader />
      </Grid>
      <Grid item xs={12}>
        <ContactTable />
      </Grid>
    </Grid>
  );
};

export default Contact;
