
import holidays from "./endpoints/holidays";
import csv from "./endpoints/csv";
import user from "./endpoints/user";
import contact from "./endpoints/contact";
import message from "./endpoints/message";
import appointment from "./endpoints/appointment";

const repositories = {
    holidays,
    contact,
    csv,
    message,
    appointment,
    user
};

export const factory = {
    get: (name) => repositories[name],
};