import { useState, forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  Message,
} from "../../../app/slices/messageSlice";
import { Controller, useForm } from "react-hook-form";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MessageForm = () => {
  const _message= useSelector((state) => state.message.message);

  const isAdd = _message.form.id ? false : true;
  const { control, reset, watch, register, handleSubmit, setValue } = useForm({
    defaultValues: _message.form,
  });

  const onSubmit = (data) => {
    if (isAdd) {
      data.all =true;
      dispatch(Message.SendMessageAll(data));
    } else {
      // dispatch(Message.update(data));
    }
  };
  useEffect(() => {
    reset({ ..._message.form });
  }, [_message.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, []);

  const dispatch = useDispatch();

  const handlDialog = () => {
    dispatch(setDialog());
  };

  return (
    <Dialog
      open={_message.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handlDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          gutterBottom
          component="div"
        >
           ارسال للكل 
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="message"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label=" نص الرسالة"
                    fullWidth
                    multiline
                    rows={8}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary"fullWidth>
                ارسال
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default MessageForm;
