import Typography from "@mui/material/Typography";
import {
  Button,
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Chip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiBuildings, BiTrash, BiEdit,BiUndo } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Message } from "../../../../app/slices/messageSlice";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Stack } from "@mui/system";

const ContactTabel = () => {
  // const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.message.messages);

  // useEffect(() => {
  //   console.log(messages)
  //   console.log(Message.loading)
  // }, [messages])
  const columns = [
    {
      field: "name",
      headerName: "الاسم",
      flex: 1,
      renderCell: (params) => {
        return (
          (params.row.contact ? params.row.contact.name : "")
        )
      }
    },
    {
      field: "phone_number",
      headerName: " رقم الهاتف",
      flex: 1,
      renderCell: (params) => {
        return (
          (params.row.contact ? params.row.contact.phone_number : "")
        )
      }
    },
    {
      field: "message",
      headerName: "الرسالة",
      flex: 3,
    },
    {
      field: "created_at",
      headerName: "وقت الرسالة",
      flex: 1,
      renderCell: (params) => {
        return (
          (params.row.created_at ? dayjs(params.row.created_at).format("YYYY-MM-DD / HH:mm") : "")
        )
      }
    },
    {
      field: "status",
      headerName: "الحالة",
      flex: 1,
      renderCell: (params) => {
        var status = params.row.status == "check" ? "جاري الارسال" : (params.row.status == "success" ? "تم الارسال" : "فشل")
        var color = params.row.status == "check" ? "warning" : (params.row.status == "success" ? "success" : "error")
        return (
          params.row.status ?
            <Stack direction={"row"} spacing={1}>
              <Chip label={status} color={color} />
              {params.row.status != "success" && params.row.status != 'check' && (
                <IconButton onClick={()=>{
                  var phone=params.row.contact.phone_number
                  var body={
                    messages:[params.row.message],
                    mobile_contacts:[phone]
                  }
                  dispatch(Message.ResendMessage(body))
                }}>
                  <BiUndo/>
                </IconButton>
            )}
            </Stack>
            : <></>
        );
      },
    },
  ];

  useEffect(() => {
    // if (!search) {
    dispatch(Message.getAll({ skip: 0, take: 15 }));
    // }
    // const timeout = setTimeout(() => {
    //   if (search) {
    //     dispatch(message.getAll({ search: search, skip: 0, take: 15 }));
    //   }
    // }, 500);
    // return () => clearTimeout(timeout);
  }, []); //search

  const navigate = useNavigate();

  const goTo = (path) => {
    // navigate(path);
  };

  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={{}}>
          <Grid item xs={2}>
            {/* <TextField
              size="small"
              onChange={(e) => {
                //   setSearch(e.target.value);
              }}
              label="بحث عن جهة اتصال "
              fullWidth
            /> */}
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={messages.data}
                columns={columns}
                pageSize={15}
                rowCount={messages.total}
                loading={messages.loading}
                rowHeight={65}
                pagination
                paginationMode="server"
                selectionModel={[]}
                onPageChange={(newPage) =>
                  dispatch(
                    Message.getAll({
                      // search: search,
                      skip: newPage * 15,
                      take: 15,
                    })
                  )
                }
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ContactTabel;
