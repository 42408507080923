import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, Checkbox, Typography } from "@mui/material";
import { useEffect, forwardRef, useState } from "react";
// import { getAllReferees, clearReferee } from "../../app/modules/refereesSlice";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";

import { factory } from "../../api/apiFactory";
const contactApi = factory.get("contact");
export const SelectContact = forwardRef((props, ref) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const [options, setOptions] = useState([]);
  const [skip, setSkip] = useState(0);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const { appointment } = useSelector((state) => state.appointment);

  const fetchContacts = (search) => {
    setLoading(true);
    contactApi.getAll({ skip: 0, take: 10, search: search }).then((res) => {
      setOptions(res.data.result);
      setLoading(false);
    });
  };

  // useEffect(() => {
  //   console.log(appointment.form)
  //   if (appointment.form.contact_id)
  //     fetchContacts(appointment.form.contact.name)
  // }, [appointment.form.contact_id])
  // useEffect(() => {
  //   contactApi.getAll({ skip: 0, take: 40, search: search }).then((res) => {
  //     setOptions(res.data.data);
  //   });
  //   return () => {};
  // }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (search) {
        fetchContacts(search);
      }
    }, 500);
    return () => clearTimeout(timeout);
  }, [search]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);


  return (
    <Autocomplete
      {...props}
      ref={ref}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      // isOptionEqualToValue={(option, value) =>
      //   option.id === value.id
      // }
      getOptionLabel={(option) => option.name + " - " + option.phone_number}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="المراجع"
          size="small"
          onChange={(e, value) => {
            setSearch(e.target.value);
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
});
