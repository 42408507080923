import {
  Button,
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Chip,
  Switch,
  Typography,
  FormControlLabel,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiBuildings, BiTrash, BiEdit } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Appointment } from "../../../../app/slices/appointmentSlice";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Stack } from "@mui/system";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const AppointmentTabel = () => {
  const today=dayjs(new Date()).format("YYYY-MM-DD");
  const [search, setSearch] = useState({ text: "", date: today });
  const [all, setAll] = useState(false);
  const dispatch = useDispatch();
  const appointments = useSelector((state) => state.appointment.appointments);

  const columns = [
    {
      field: "name",
      headerName: "الاسم",
      flex: 1,
      renderCell: (params) => {
        return (
          (params.row.contact ? params.row.contact.name : "")
        )
      }
    },
    {
      field: "phone_number",
      headerName: " رقم الهاتف",
      flex: 1,
      renderCell: (params) => {
        return (
          (params.row.contact ? params.row.contact.phone_number : "")
        )
      }
    },
    {
      field: "section",
      headerName: "الرسالة",
      flex: 1,
      renderCell: (params) => {
        return (
          (params.row.section ? params.row.section.name : "")
        )
      }
    },
    {
      field: "appointment_date",
      headerName: "موعد الحجز",
      flex: 1,
      renderCell: (params) => {
        return (
          (params.row.appointment_date ? dayjs(params.row.appointment_date).format("YYYY-MM-DD / HH:mm") : "")
        )
      }
    },
    {
      field: "send_message",
      headerName: "تذكير؟",
      flex: 1,
      renderCell: (params) => {
        return (
          <Switch
            checked={params.row.send_message}
            onChange={(e, newValue) => {
              dispatch(Appointment.updateSendMessage(params.row, newValue))
            }}
          />
        );
      },
    },
    {
      field: "message_sent",
      headerName: "حالة التذكير",
      flex: 1,
      renderCell: (params) => {
        return (
          <Chip label={params.row.message_sent ? "تم التذكير" : "لم يتم التذكير"} color={params.row.message_sent ? "success" : "warning"} />
        );
      },
    },
    {
      field: "operations",
      headerName: "",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => dispatch(Appointment.getById(params.row.id))}
            >
              <BiEdit />
            </IconButton>
            <IconButton
              onClick={() => dispatch(Appointment.deleteById(params.row.id))}
            >
              <BiTrash />
            </IconButton>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (!search) {
      dispatch(Appointment.getAll({ skip: 0, take: 15, search: search.text, date: search.date }));
    }
    const timeout = setTimeout(() => {
      if (search) {
        dispatch(Appointment.getAll({ search: search.text, date: search.date, skip: 0, take: 15 }));
      }
    }, 500);
    return () => clearTimeout(timeout);
  }, [search]); //search

  const navigate = useNavigate();

  const goTo = (path) => {
    // navigate(path);
  };

  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={{}}>
          <Stack direction={"row"} spacing={1}>
            <TextField
              size="small"
              onChange={(e) => {
                setSearch({ ...search, text: e.target.value });
              }}
              label="بحث عن اسم "
              fullWidth
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="بحث عن تاريخ"
                inputFormat="YYYY-MM-DD"
                value={search.date}
                onChange={(newValue) => {
                  setSearch(
                    { ...search, date: newValue ? newValue.format("YYYY-MM-DD") : null }

                  );
                }}
                renderInput={(params) => (
                  <TextField size="small" fullWidth {...params} />
                )}
              />
            </LocalizationProvider>
            <FormControlLabel
              control={
                <Switch
                  checked={all}
                  onChange={(e, checked) => {
                    setAll(checked);
                    setSearch({ ...search, date: checked?null: today })
                  }} />} label="الكل" />
          </Stack>

          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={appointments.data}
                columns={columns}
                pageSize={15}
                rowCount={appointments.total}
                loading={appointments.loading}
                rowHeight={65}
                pagination
                paginationMode="server"
                selectionModel={[]}
                onPageChange={(newPage) =>
                  dispatch(
                    Appointment.getAll({
                      search: search.text,
                      date: search.date,
                      skip: newPage * 15,
                      take: 15,
                    })
                  )
                }
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AppointmentTabel;
