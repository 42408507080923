import dayjs from "dayjs";
import {
    createSlice
} from "@reduxjs/toolkit";
import {
    showNotification
} from "./notificationSlice";
import {
    factory
} from "../../api/apiFactory";
const appointmentsApi = factory.get("appointment");
const initialState = {
    appointment: {
        loading: false,
        dialog: false,
        sections:[],
        form: {
            id: "",
            contact_id: "",
            contact:{
                id:"",
                name:"",
                phone_number:""
            },
            send_message:true,
            appointment_date: dayjs(new Date()).format("YYYY-MM-DD HH:mm")
        },
    },
    appointments: {
        loading: false,
        data: [],
        total: 0,
    },
}

export const appointmentsSlice = createSlice({
    name: "appointments",
    initialState,
    reducers: {
        setDialog: (state, action) => {
            state.appointment.dialog = !state.appointment.dialog
        },
        setSections: (state, {payload}) => {
            state.appointment.sections = payload
        },
        setDataTable: (state, {
            payload
        }) => {
            state.appointments.data = payload.data.result
            state.appointments.total = payload.data.count
        },
        setById: (state, {
            payload
        }) => {
            state.appointment.form = payload
        },
        setLoading: (state, action) => {
            state[action.payload].loading = !state[action.payload].loading;
        },
        resetForm: (state, action) => {
            state.appointment.form = initialState.appointment.form
        }
    }
})

export const {
    setLoading,
    setDialog,
    setById,
    setSections,
    setDataTable,
    resetForm
} = appointmentsSlice.actions

export default appointmentsSlice.reducer;

//axios
const getAll = (data) => async (dispatch) => {
    try {
        dispatch(setLoading('appointments'));
        const res = await appointmentsApi.getAll(data)
        dispatch(setDataTable(res));
        dispatch(setLoading('appointments'));

    } catch (err) {
        dispatch(setLoading('appointments'));
        throw new Error(err);
    }
};
const deleteById = (id) => async (dispatch) => {
    try {
        await appointmentsApi.delete(id)
        dispatch(showNotification({
            message: "تم حذف بنجاح",
            type: "success"
        }));
        dispatch(getAll())
    } catch (err) {
        throw new Error(err);
    }
};
const getById = (id) => async (dispatch) => {
    try {
        dispatch(setLoading('appointments'));
        const res = await appointmentsApi.getById(id)
        dispatch(setById(res.data.result[0]));

        dispatch(setDialog())
        dispatch(setLoading('appointments'));
    } catch (err) {
        dispatch(setLoading('appointments'));
        throw new Error(err);
    }
};
const create = (data) => async (dispatch) => {
    try {
        await appointmentsApi.create(data)
        dispatch(showNotification({
            message: "تم الاضافة بنجاح",
            type: "success"
        }));
        dispatch(setDialog())
        dispatch(getAll())
    } catch (err) {
        dispatch(setLoading('appointment'));
        throw new Error(err);
    }
};
const update = (data) => async (dispatch) => {
    try {
        await appointmentsApi.update(data.id, data)
        dispatch(showNotification({
            message: "تم التعديل بنجاح",
            type: "success"
        }));
        dispatch(setDialog())
        dispatch(getAll())
    } catch (err) {
        dispatch(setLoading('appointment'));
        throw new Error(err);
    }
};

const updateSendMessage = (data,send_message) => async (dispatch) => {
    try {
        var _data ={...data}
        _data.send_message=send_message
        await appointmentsApi.update(_data.id, _data)
        dispatch(showNotification({
            message: "تم التعديل بنجاح",
            type: "success"
        }));
        dispatch(getAll())
    } catch (err) {
        dispatch(setLoading('appointment'));
        throw new Error(err);
    }
};

const getSections = () => async (dispatch) => {
    try {
        const res=await appointmentsApi.getAllSections()
        dispatch(setSections(res.data.result))
    } catch (err) {
        throw new Error(err);
    }
};

export const Appointment = {
    getAll,
    deleteById,
    getById,
    create,
    update,
    getSections,
    updateSendMessage
}