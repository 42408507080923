import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getAll(data) {
        let params = "";
        if (data) {
            Object.keys(data).forEach(key => {
                if(data[key]) params += `${key}=${data[key]}&`;
            });
        }
        return api.get(`/api/schedule?${params}`);
    },
    getById(id) {
        return api.get(`/api/schedule/show?schedule_id=${id}`);
    },
    update(id, data) {
        return api.put(`/api/schedule/${id}`, data);
    },
    create(data) {
        return api.post(`/api/schedule`, data);
    },
    delete(id) {
        return api.delete(`/api/schedule/${id}`);
    },
    getAllSections() {
        return api.get(`/api/section`);
    },
}