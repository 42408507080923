import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    // getAll(data) {
    //     let params = "";
    //     if (data) {
    //         Object.keys(data).forEach(key => {
    //             params += `${key}=${data[key]}&`;
    //         });
    //     }
    //     return api.get(`/api/user?${params}`);
    // },
    getAll(){
        return api.get(`/api/user`);
    },
    login(data){
        return api.post(`/api/login`,data);
    },
    logout(){
        return api.post(`/api/logout`);
    },
    // getById(id) {
    //     return api.get(`/api/v1/user/${id}`);
    // },
    // update(id, data) {
    //     return api.put(`/api/v1/user/${id}`, data);
    // },
    // create(data) {
    //     return api.post(`/api/v1/user`, data, {
    //         headers: {
    //             "Content-Type": "multipart/form-data"
    //         }
    //     });
    // },
    // delete(id) {
    //     return api.delete(`/api/v1/user/${id}`);
    // },
}